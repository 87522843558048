@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@300&family=Mukta:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@300&family=Mukta:wght@300&display=swap");
.slide {
  margin-left: 8vw;
  margin-right: 8vw;
}
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.corouselprovider {
  margin-bottom: 1%;
  width: 100%;
}
.card_button {
  font-size: 35px;
  border: none;
  width: 80px;
  height: fit-content;
  border-radius: 5px;
  background-color: #ffc107;
  color: white;
}
.events_container_home {
  background: url("../Assets/home_bg.mp4");
  background-repeat: no-repeat;
  background-size: cover;
}
.home_sharktank {
  /* font-family: Verdana, sans-serif; */
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 5px 0 5px -5px grey, 0 5px 5px -5px grey, -5px 0 5px -5px grey;
  padding: 2%;
  align-items: center;
  width: 100%;
  height: max-content;
  transition: 1.5s;
}
.home_sharktank div {
  justify-content: center;
  width: 100%;
  z-index: 1;
  flex-wrap: wrap;
}
.home_sharktank h2 {
  align-self: flex-start;
  color: #ffc107;
  text-shadow: 1px 1px 0px grey;
}
.home_sharktank div div {
  box-shadow: 0 0 10px grey;
  border-radius: 10px;
  width: 350px;
  height: fit-content;
  margin-left: 3%;
  margin-top: 2%;
  cursor: pointer;
  position: relative;
}
.home_sharktank div div:hover img {
  filter: brightness(50%);
  scale: 1.1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.home_sharktank div div:hover p {
  padding-top: 10%;
}
.home_sharktank div div:hover button {
  visibility: visible;
}
.home_sharktank button {
  visibility: hidden;
  position: absolute;
  box-shadow: 0 0 10px black;
  top: 30%;
  left: 30%;
  width: fit-content;
  height: fit-content;
}
.home_sharktank div div p {
  font-size: 12px;
  z-index: 5;
  margin: 0;
  height: fit-content;
  transition: 0.2s;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: whitesmoke;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.home_sharktank div div img {
  scale: 1;
  transition: 0.2s;
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 2;
}
.home_sharktank span {
  transition: 1.5s;
  position: absolute;
  background-color: rgb(246, 180, 180);
  height: 100%;
  width: 20%;
  top: 0;
  z-index: 1;
}
.numberdata_home {
  display: flex;
  /* font-family: Verdana, sans-serif; */
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  height: fit-content;
}
.numberdata_home div {
  margin: 2%;
}
.numberdata_home div div {
  margin-left: 10%;
  transition: 0.2s ease-in;
  color: white;
  background-color: limegreen;
}
.numberdata_home div div:hover {
  background-color: white;
  color: green;
}
.numberdata_home span div:nth-child(1) div {
  animation: hovereffect 4s 10 ease;
}
.numberdata_home span div:nth-child(2) div {
  animation: hovereffect2 4s 10 ease;
}
.numberdata_home span div:nth-child(3) div {
  animation: hovereffect3 4s 10 ease;
}
.numberdata_home span div:nth-child(4) div {
  animation: hovereffect4 4s 10 ease;
}
.numberdata_home span div:nth-child(5) div {
  animation: hovereffect5 4s 10 ease;
}
@keyframes hovereffect5 {
  70% {
    background-color: limegreen;
    color: white;
  }

  80% {
    background-color: white;
    color: green;
  }

  100% {
    background-color: limegreen;
    color: white;
  }
}
@keyframes hovereffect4 {
  50% {
    background-color: limegreen;
    color: white;
  }
  60% {
    background-color: white;
    color: green;
  }
  80% {
    background-color: limegreen;
    color: white;
  }
}
@keyframes hovereffect3 {
  25% {
    background-color: limegreen;
    color: white;
  }
  40% {
    background-color: white;
    color: green;
  }
  60% {
    background-color: limegreen;
    color: white;
  }
}
@keyframes hovereffect2 {
  15% {
    background-color: limegreen;
    color: white;
  }
  20% {
    background-color: white;
    color: green;
  }

  40% {
    background-color: limegreen;
    color: white;
  }
}
@keyframes hovereffect {
  10% {
    background-color: white;
    color: green;
  }
  20% {
    background-color: limegreen;
    color: white;
  }
}
.img_content_home {
  margin-top: 5%;
  padding: 5%;
}
.img_content_home div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.img_content_home div img {
  object-fit: cover;
}
.img_content_home div div {
  padding-left: 5%;
  padding-right: 5%;
}
footer span {
  display: flex;
  flex-direction: row;
}
.span_div_home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.form_div_home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.form_div_home form {
  width: 50%;
}
.copyrights_footer {
  margin-left: 5%;
  margin-right: 5%;
}
@media only screen and (max-width: 800px) and (min-width: 400px) {
  .home_sharktank div div {
    margin-top: 4%;
  }

  .numberdata_home {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .numberdata_home span {
    margin-top: 5%;
  }

  .numberdata_home div {
    margin: 2%;
  }
}

@media only screen and (max-width: 400px) {
  .copyrights_footer {
    margin-left: 0;
    margin-right: 0;
  }
  .home_sharktank div div {
    width: 250px;
    margin-top: 5%;
  }
  .numberdata_home {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .numberdata_home span {
    margin-top: 5%;
  }
  .numberdata_home div {
    margin: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .form_div_home form {
    width: 100%;
  }
  footer span div:nth-child(2) {
    flex-direction: column;
    align-items: center;
  }
  .img_content_home {
    padding: 0;
  }
  .img_content_home div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img_content_home div:nth-child(2) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media only screen and (max-width: 700px) {
  .span_div_home {
    padding-right: 0;
  }
  footer span {
    flex-direction: column;
  }
}
