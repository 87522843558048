.head_aboutus {
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-shadow: 0 0 10px black;
  background-repeat: no-repeat;
  background-size: cover;
}
.head_aboutus img {
  width: 30%;
  height: 100px;
  object-fit: contain;
}
.timeline {
  border-left: 10px solid rgba(38, 194, 129, 1);
  text-align: left;
  font-size: xx-large;
  box-shadow: 0 0 5px rgba(38, 194, 129, 0.4);
}
.timeline div {
  margin-left: 2%;
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: x-large;
}
.celebrity {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: x-large;
  justify-content: center;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(../Assets/pexels-pixabay-40142.jpg);
  box-shadow: 0 0 5px rgba(38, 194, 129, 0.4);
  background-repeat: no-repeat;
  background-size: cover;
}
.whoarewe {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.whoarewe ul {
  list-style-type: square;
  list-style-position: inside;
}
