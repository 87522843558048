.login_container {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  backdrop-filter: blur(8px);
}
* {
  /* font-family: "Inter"; */
}
.login {
  z-index: 5;
  margin-top: 70px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(3, 201, 169, 0.2);
  display: flex;

  height: fit-content;

  background-color: rgb(247, 243, 243);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button:hover p {
  text-decoration: underline;
}
.button:hover span img {
  scale: 1.3;
}
.button {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 50px;
  border-radius: 40px !important;
  outline: none;
  border: none;
  justify-content: center;

  padding: 0;
}
.button span {
  flex: 0.2;
  background-color: white;
  height: 100%;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button span img {
  transition: 0.2s;
  width: 50%;
  height: 50%;
  object-fit: contain;
}
.button p {
  flex: 0.8;

  background-color: blanchedalmond;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StartJourney {
  width: 98%;
  margin-bottom: 1%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(3, 201, 169, 01);
}
