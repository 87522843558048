@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
* {
  scroll-behavior: smooth;
}

.ContentApp {
  min-height: 100vh;
}
body {
  overflow-x: hidden;
}
.LoadingImage {
  width: 30%;
  height: 30%;

  box-shadow: 0 0 10px grey;
  object-fit: fill;
  animation: ease zoom infinite 1s;
}

/* .bg-kct {
  background-image: url(/Assets/Kctimage.jpeg);
} */

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .LoadingImage {
    object-fit: contain;
    height: 30%;
    width: 70%;
  }
}
@media only screen and (max-width: 599px) {
  .LoadingImage {
    object-fit: contain;
    height: 30%;
    width: 70%;
  }
}
@keyframes zoom {
  0% {
    scale: 1;
    box-shadow: 0 0 20px grey;
  }

  100% {
    scale: 1.1;
    box-shadow: 0 0 0px grey;
  }
}
.LoadingImageContainer {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*
 * Loading Dots
 * Can we use pseudo elements here instead :after?
 */
.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 0.6em;
  height: 0.6em;
  margin: 0.19em;
  background: #007db6;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

/*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
.loading span:nth-of-type(2) {
  background: #008fb2;
  animation-delay: 0.2s;
}

.loading span:nth-of-type(3) {
  background: #009b9e;
  animation-delay: 0.4s;
}

.loading span:nth-of-type(4) {
  background: #00a77d;
  animation-delay: 0.6s;
}

.loading span:nth-of-type(5) {
  background: #00b247;
  animation-delay: 0.8s;
}

.loading span:nth-of-type(6) {
  background: #5ab027;
  animation-delay: 1s;
}

.loading span:nth-of-type(7) {
  background: #a0b61e;
  animation-delay: 1.2s;
}

.bg-kct
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.bg-gradient-radial {
  background: radial-gradient(
    circle closest-side,
    #ffffff,
    rgb(255, 255, 255, 0)
  );
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
@keyframes loading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
